* {
  outline: none !important;
}

.inputform {
  color: #000;
  width: 40em;
  margin: 5% auto;
}

body {
  font-family: Noto Sans, sans-serif !important;
}

.card {
  padding: 1em;
  border: none !important;
}

input[type="password"], input[type="text"], input[type="number"], input[type="date"], select {
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  outline: none;
  width: 15em;
  padding: 10px;
}

a {
  color: #000 !important;
}

table {
  margin: auto;
  box-shadow: 0 0 4px #c9c9c9;
  color: #000 !important;
}

td, th {
  box-sizing: border-box;
  border: 1px solid #c9c9c9;
  vertical-align: middle !important;
  padding: 6px !important;
}

.pickerclass {
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  outline: none;
  width: 15em;
  padding: 10px;
}

.pickerclass > div {
  border: none;
  margin-left: 20%;
}

.pickerclass > div > div > input {
  border: none;
  border-radius: 0;
  outline: none;
  width: 44px;
  margin: 0;
  padding: 2px;
}

.issueid {
  color: #555;
  margin-right: 1em;
  font-weight: bold;
}

.issuetitle {
  font-weight: 700;
}

.dueon {
  font-weight: 500;
}

.greyout {
  color: #999;
}

.name {
  font-size: 1.2em;
}

.projectname {
  background: #e8eaf6;
  font-weight: 900;
}

.statsbar {
  width: 100%;
  min-width: 10em;
}

.feathead {
  color: #007bff;
}

.bughead {
  color: #dc3545;
}

.nodue {
  background: #ffcc80;
}

.rtd {
  text-align: right;
}

.ltd {
  text-align: left;
}

.topholder {
  justify-content: flex-end;
  align-items: center;
  padding-top: .3em;
  padding-bottom: 1em;
  display: flex;
}

.projectname {
  padding: 10px;
}

.legend {
  justify-content: center;
  align-items: center;
  display: flex;
}

.datewrap {
  margin-top: 6%;
  padding: 2em;
  font-size: 1.3em;
  font-weight: bold;
}

.dateinput {
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  outline: none;
  margin: 10px;
  padding: 10px;
}

.daystats {
  margin: 2em;
}

.badge-primary {
  background: #536dfe !important;
}

.badge-danger {
  background: #ff5252 !important;
}

.nameth {
  background: #e8eaf6;
  line-height: 100%;
}

.dualdisp {
  text-align: center;
  width: 100%;
  height: 100%;
  margin-top: .75em;
  margin-bottom: .75em;
}

.screenheader {
  color: #fff;
  text-align: center;
  z-index: 100;
  background-color: #536dfe;
  width: 100%;
  height: 4em;
  padding: .7em;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 7px 1px #c9c9c9;
}

.ctd {
  text-align: center;
}

.iconhold {
  float: left;
  z-index: 1;
  margin-bottom: -24px;
  position: relative;
  bottom: .9em;
}

.dta {
  text-align: center;
  vertical-align: middle;
  height: 100%;
  margin-bottom: -12px;
}

.ndth {
  padding: 1em;
}

.strechtable {
  width: 100%;
}

.reporttd {
  border: none;
}

.corner {
  padding: 0;
}

.dstable {
  width: 800px;
}

.floatlegend {
  border: 1px solid #555;
  border-top-left-radius: 5px;
  padding: 1em;
  position: fixed;
  bottom: 0;
  right: 0;
}

.namedstatsdisplay {
  margin: 2em auto;
}

.statsname {
  font-size: 1.2em;
}

.monthreport {
  padding: .5em;
}

.monthlytd, .reportth {
  padding: 0;
}

.tablayout {
  border-radius: 5px;
  min-height: 700px;
  box-shadow: 0 0 7px 1px #c9c9c9;
}

.nav-tabs {
  border: none !important;
}

.nav-link:hover {
  border-color: #0000 !important;
}

.prgrs {
  margin-top: 20%;
  padding: 1em;
}

.nav-link {
  background-color: #fff !important;
  border: none !important;
  border-bottom: 3px solid #fff !important;
  border-top-left-radius: 5px !important;
}

.nav-link:hover {
  cursor: pointer;
}

.nav-link.active {
  color: #007bff !important;
  border-color: #007bff !important;
  border-top-style: none !important;
  border-top-width: medium !important;
  border-left-style: none !important;
  border-left-width: medium !important;
  border-right-style: none !important;
  border-right-width: medium !important;
}

.floatit {
  box-shadow: 0 0 4px #c9c9c9;
}

.hiddentable {
  table-layout: fixed;
  width: 0;
  height: 0;
  position: absolute;
  left: -2000px;
  box-shadow: 0 0 #fff;
}

.hiddentable td {
  border: none;
}

.dblink {
  text-align: center;
  width: 100%;
}

.linkdb {
  float: right;
  background: #007bff;
  border-radius: .25rem;
  width: 100%;
  padding: .4em;
  display: block;
  color: #fff !important;
  margin: 0 !important;
}

.linkdb:hover {
  background: #01579b;
}

.floatr {
  float: right;
}

.hoverdark:hover > td {
  background: #e9e9e9;
}

.namepush {
  flex-grow: 1;
}

.fa-download {
  color: #000;
}

.fa-download :hover {
  color: #007bff;
}

.selectspan {
  width: 15em;
}

.printlonly {
  display: none;
}

.small {
  font-size: .8em;
}

.italics {
  font-style: italic;
}

.forcer {
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}

.linkdb {
  margin-left: .5em;
}

.formerror {
  color: #ff5252;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
  font-weight: bold;
  display: flex;
}

.noshadow {
  width: 35em;
  box-shadow: 0 0;
}

.noborder {
  border: none;
  padding: 4px;
}

noborder > td {
  border: none;
}

.bm-burger-button {
  background: #639;
  width: 24px;
  height: 24px;
}

.slide-menu {
  width: 24px;
  height: 24px;
}

.menu-button {
  color: #fff;
  background: none;
  border: none;
  padding-left: .5em;
  padding-right: 1em;
}

.side-nav:hover {
  background: #c9c9c9;
}

.side-nav {
  cursor: pointer;
  padding: 1em;
}

ul {
  list-style: none;
}

.nooverflow {
  overflow: hidden !important;
}

.gone {
  display: none;
}

.submitter {
  width: 100%;
}

.alternate:nth-child(2n) {
  background: #f2f2f2;
}

.ccerror {
  color: #f4ff81;
  margin-right: .5em;
}

.sidebar-links {
  padding-top: 3em;
}

.sidebar-link {
  color: #000;
  padding: 1em 3em;
}

.sidebar-link:hover {
  background: #c9c9c9;
}

.zeropad {
  padding: 0 !important;
}

.namehead {
  display: flex;
}

.padit {
  cursor: pointer;
  padding-left: 7px;
}

.padit:hover {
  color: #007bff;
}

.col_mp {
  color: #000;
}

.selected_cell, .col_mp:hover {
  color: #000 !important;
  background: #e8eaf6 !important;
}

.selectspansmall {
  width: 15em;
}

.nomarg {
  margin-right: 0 !important;
}

.sidenav {
  background: #fff;
  width: 200px;
  height: 100vh;
  padding-top: 4.5em;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 4px 1px #777;
}

.abouttext {
  width: 80%;
  margin: 4.5em auto;
}

.abouttext h1 {
  color: #37474f;
  margin-top: 2em;
  margin-bottom: 1em;
}

.abouttext p {
  text-align: justify;
}

.abouttext a {
  text-decoration: underline !important;
}

.about {
  justify-content: space-between;
  width: calc(100% - 200px);
  margin-left: 200px;
  display: flex;
}

.ytvideo {
  justify-content: center;
  align-items: center;
  display: flex;
}

iframe {
  background: #000 url("https://media.giphy.com/media/3o7bu3XilJ5BOiSGic/giphy.gif") 50% / 4rem no-repeat;
  box-shadow: 0 0 13px 1px #c9c9c9;
}

.dcsv {
  width: 130px;
}

@media only screen and (width <= 768px) {
  .sidenav {
    flex-direction: row;
    justify-content: space-evenly;
    width: 100vw;
    height: 4em;
    padding: 0;
    display: flex;
    top: 4em;
    overflow-x: scroll;
  }

  .navlink {
    padding: 10px;
  }

  .abouttext {
    width: 95%;
    margin: 8em auto;
  }
}

@media only screen and (width <= 1400px) {
  .about {
    justify-content: flex-end;
    display: flex;
  }
}

@media print {
  .printtext {
    font-size: 1.35em !important;
  }

  tr {
    padding: 0;
    font-size: .8em;
  }

  th {
    border: .5px solid #c9c9c9;
    padding: 4px;
  }

  .projectname {
    padding: 4px;
  }

  .ctd {
    text-align: center;
  }

  .ndth {
    padding: 1em;
  }

  .screenheader {
    display: none;
  }

  .printlonly {
    display: inline-block;
  }

  a {
    text-decoration: none;
  }
}

.lds-ring {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  position: relative;
}

.lds-ring div {
  box-sizing: border-box;
  border: 2px solid #0000;
  border-top-color: #fff;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-ring;
  display: block;
  position: absolute;
}

.lds-ring div:first-child {
  animation-delay: -.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=index.7521cce1.css.map */
