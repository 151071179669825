* {
  outline: none !important;
}
.inputform {
  color: black;
  width: 40em;
  margin: 5% auto;
}
body {
  font-family: "Noto Sans", sans-serif !important;
}
.card {
  padding: 1em;
  border: none !important;
}

input[type="password"],
input[type="text"],
input[type="number"],
input[type="date"],
select {
  border: 1px solid #c9c9c9;
  width: 15em;
  border-radius: 5px;
  padding: 10px;
  outline: none;
}
a {
  color: black !important;
}
table {
  margin: auto;
  box-shadow: 0px 0px 4px 0px #c9c9c9;
  color: black !important;
}
td,
th {
  padding: 6px !important;
  border: 1px solid #c9c9c9;
  box-sizing: border-box;
  vertical-align: middle !important;
}
.pickerclass {
  border: 1px solid #c9c9c9;
  width: 15em;
  border-radius: 5px;
  /* margin: 10px; */
  padding: 10px;
  outline: none;
}
.pickerclass > div {
  border: none;
  margin-left: 20%;
}
.pickerclass > div > div > input {
  border: none;
  width: 44px;
  border-radius: 0px;
  margin: 0px;
  padding: 2px;
  outline: none;
}
.issueid {
  color: #555;
  font-weight: bold;
  margin-right: 1em;
}
.issuetitle {
  font-weight: 700;
}
.dueon {
  font-weight: 500;
}
.greyout {
  color: #999;
}
.namedisplay {
  /* padding: 2em; */
}
.name {
  font-size: 1.2em;
}
.projectname {
  font-weight: 900;
  background: #e8eaf6;
}
.statsdisplay {
  /* margin: 1em; */
}
.statsbar {
  width: 100%;
  min-width: 10em;
}
.feathead {
  color: #007bff;
}
.bughead {
  color: #dc3545;
}
.nodue {
  background: #ffcc80;
}
.rtd {
  text-align: right;
}
.ltd {
  text-align: left;
}

.topholder {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 1em;
  padding-top: 0.3em;
  /* margin: auto; */
}
.projectname {
  padding: 10px;
}
.legend {
  display: flex;
  align-items: center;
  justify-content: center;
}
.datewrap {
  padding: 2em;
  /* box-shadow: 0px 0px 4px 0px #c9c9c9; */
  font-weight: bold;
  font-size: 1.3em;
  margin-top: 6%;
}
.dateinput {
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
  outline: none;
}
.daystats {
  margin: 2em;
}
.badge-primary {
  background: #536dfe !important;
}
.badge-danger {
  background: #ff5252 !important;
}
/* .pblue{
    background: #536DFE !important;
}
.bg-danger{
    background: #FF5252 !important;
}
.progress-bar{
    border: 1px solid black;
} */
.nameth {
  background: #e8eaf6;
  line-height: 100%;
}
.dualdisp {
  height: 100%;
  width: 100%;
  text-align: center;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.screenheader {
  width: 100%;
  background-color: #536dfe;
  color: white;
  text-align: center;
  padding: 0.7em;
  box-shadow: 0px 0px 7px 1px #c9c9c9;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  /* justify-content: spa */
  z-index: 100;
  height: 4em;
}
.ctd {
  text-align: center;
}
/* td{
    position: relative;
} */
.iconhold {
  position: relative;
  bottom: 0.9em;
  /* right: 0.1em; */
  margin-bottom: -24px;
  float: left;
  z-index: 1;
}
.dta {
  text-align: center;
  vertical-align: middle;
  height: 100%;
  margin-bottom: -12px;
  /* position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    text-align: center;
    /* position: relative; */
  /* top:0;
    right: 20%; */
  /* vertical-align: middle;
    justify-content: center;
    align-items: center;
    display: inline; */
}

.ndth {
  padding: 1em;
}
.strechtable {
  width: 100%;
}
.reporttd {
  border: none;
}
/* .fixthead{
    position: fixed;
} */
.corner {
  padding: 0px;
}
.dstable {
  width: 800px;
}
.floatlegend {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 1em;
  border: 1px solid #555;
  border-top-left-radius: 5px;
}
/* .reportth{
    visibility: hidden;
} */
.namedstatsdisplay {
  margin: 2em auto;
}
.statsname {
  font-size: 1.2em;
}
.monthreport {
  padding: 0.5em;
}
.monthlytd {
  padding: 0px;
}
.reportth {
  padding: 0px;
}
.tablayout {
  border-radius: 5px;
  box-shadow: 0px 0px 7px 1px #c9c9c9;
  min-height: 700px;
}
.nav-tabs {
  border: none !important;
}
.nav-link:hover {
  border-color: transparent !important;
}
.nav-tabs .nav-link {
  /* border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important; */
}
.prgrs {
  margin-top: 20%;
  padding: 1em;
}
.nav-link {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  background-color: #fff !important;
  border-top-left-radius: 5px !important;
  border-bottom: 3px solid #fff !important;
}
.nav-link:hover {
  cursor: pointer;
}
.nav-link.active {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  color: #007bff !important;
  border-color: #007bff !important;
}
.floatit {
  box-shadow: 0px 0px 4px 0px #c9c9c9;
}
.hiddentable {
  table-layout: fixed;
  height: 0px;
  width: 0px;
  /* color: #fff; */
  /* font-size: ; */
  box-shadow: 0px 0px 0px 0px #fff;
  position: absolute;
  left: -2000px;
  /* display: none; */
  /* visibility: hidden; */
}
.hiddentable td {
  border: none;
}

.dblink {
  width: 100%;
  text-align: center;
}
.linkdb {
  color: white !important;
  background: #007bff;
  border-radius: 0.25rem;
  padding: 0.4em;
  float: right;
  display: block;
  width: 100%;
  margin: 0px !important;
}
.linkdb:hover {
  background: #01579b;
}

.floatr {
  float: right;
  /* width: 20em; */
}
.hoverdark {
}
.hoverdark:hover > td {
  background: #e9e9e9;
}
.namepush {
  flex-grow: 1;
}
.fa-download {
  color: black;
}
.fa-download :hover {
  color: #007bff;
}
.selectspan {
  width: 15em;
  /* margin-right: 10px; */
}
.printlonly {
  display: none;
}
.small {
  font-size: 0.8em;
}
.italics {
  font-style: italic;
}
.forcer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.linkdb {
  margin-left: 0.5em;
}
.formerror {
  color: #ff5252;
  font-size: 1.4em;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.noshadow {
  box-shadow: 0px 0px 0px 0px;
  width: 35em;
}
.noborder {
  border: none;
  padding: 4px;
}
noborder > td {
  border: none;
}
.bm-burger-button {
  height: 24px;
  width: 24px;
  background: rebeccapurple;
}
.slide-menu {
  width: 24px;
  height: 24px;
}
.menu-button {
  background: transparent;
  border: none;
  padding-right: 1em;
  padding-left: 0.5em;
  color: #fff;
}
.side-nav:hover {
  background: #c9c9c9;
}
.side-nav {
  padding: 1em;
  cursor: pointer;
}
ul {
  list-style: none;
  list-style-position: outside;
}
.nooverflow {
  overflow: hidden !important;
}
.gone {
  display: none;
}
.submitter {
  width: 100%;
}
.alternate:nth-child(even) {
  background: #f2f2f2;
}
.ccerror {
  color: #f4ff81;
  margin-right: 0.5em;
}
.sidebar-links {
  padding-top: 3em;
}
.sidebar-link {
  color: black;
  padding: 1em;
  padding-right: 3em;
  padding-left: 3em;
}
.sidebar-link:hover {
  background: #c9c9c9;
}
.zeropad {
  padding: 0px !important;
}
.namehead {
  display: flex;
}
.padit {
  cursor: pointer;
  padding-left: 7px;
}
.padit:hover {
  color: #007bff;
}
.col_mp {
  color: black;
}
.selected_cell {
  background: #e8eaf6 !important;
  color: black !important;
}
.col_mp:hover {
  background: #e8eaf6 !important;
  color: black !important;
}
.selectspansmall {
  width: 15em;
}
.nomarg {
  margin-right: 0px !important;
}

.sidenav {
  padding-top: 4.5em;
  background: #fff;
  width: 200px;
  box-shadow: 0px 0px 4px 1px #777;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.abouttext {
  margin: 4.5em auto;
  width: 80%;
}

.abouttext h1 {
  margin-top: 2em;
  margin-bottom: 1em;
  color: #37474f;
}
.abouttext p {
  text-align: justify;
}
.abouttext a {
  text-decoration: underline !important;
}
.about {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 200px);
  margin-left: 200px;
}
.ytvideo {
  display: flex;
  justify-content: center;
  align-items: center;
}
iframe {
  box-shadow: 0px 0px 13px 1px #c9c9c9;
  background: black;
  background-image: url(https://media.giphy.com/media/3o7bu3XilJ5BOiSGic/giphy.gif); /* Youtube type spinner */
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 4rem;

  /* background-size: contain;  */
}
.dcsv {
  width: 130px;
}

@media only screen and (max-width: 768px) {
  .sidenav {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100vw;
    height: 4em;
    top: 4em;
    padding: 0px;
    overflow-x: scroll;
  }
  .navlink {
    padding: 10px;
  }
  .abouttext {
    margin: 8em auto;
    width: 95%;
  }
}

@media only screen and (max-width: 1400px) {
  .about {
    display: flex;
    justify-content: flex-end;
  }
  .abouttext {
    /* margin: 0px; */
    /* padding-right: 15%;  */
  }
}
@media print {
  .printtext {
    font-size: 1.35em !important;
  }
  tr {
    font-size: 0.8em;
    padding: 0px;
  }
  th {
    border: 0.5px solid #c9c9c9;
    padding: 4px;
  }
  .projectname {
    padding: 4px;
  }
  .ctd {
    text-align: center;
  }
  .ndth {
    padding: 1em;
  }
  /* .daystats{
        padding-top: 7em;
    } */
  .screenheader {
    display: none;
  }
  .printlonly {
    display: inline-block;
  }
  a {
    text-decoration: none;
  }
  /* .reportth{
        visibility: visible;
    }
     */
}

/* For spinner */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 1rem;
  height: 1rem;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 1rem;
  height: 1rem;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
